import Vue from "vue";
import Router from "vue-router";

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `./${view}.vue`);
}

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && savedPosition.y) {
      return window.scrollTo({
        top: savedPosition.offsetTop,
        behavior: "smooth",
      });
    } else {
      // return window.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  routes: [
    {
      path: "/",
      name: "Homepage",
      component: loadView("Homepage"),
    },
    {
      path: "/enter",
      name: "Enter",
      component: loadView("Enter"),
    },
  ],
});

export default router;
